@font-face {
  font-family: "Cooper Black Italic";
  src: url("./fonts/CooperBlackStd-Italic.otf");
}
@font-face {
  font-family: "Cooper Black";
  src: url("./fonts/CooperBlackStd.otf");
}
@font-face {
  font-family: "Univers";
  src: url("./fonts/UniversLTStd.otf");
}

.ContactWrapper {
  display: block;
  min-height: calc(90vh - 34px);
  height: auto;
  max-width: 100%;
  min-width: fit-content;
  /* margin-top: 10vh; */
  overflow-y: auto;
}
.TitleWrapper {
  display: none;
}
.ContactInnerWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  width: auto;
}
.leftContactPanel {
  display: block;
  background-color: rgba(255, 243, 225, 255);
  height: 90vh;
  width: 50vw;
  background-image: url(./images/PresentDudeWide-960.svg);
  background-repeat: no-repeat;
  background-position: bottom;
}
.Helloh1 {
  font-family: "Cooper Black Italic";
  font-size: 5vw;
  margin-left: 5vw;
  margin-top: 30vh;
}
.Helloh12 {
  font-family: "Cooper Black Italic";
  font-size: 5vw;
  margin-left: 8vw;
}
.rightContactPanel {
  height: 100%;
  width: 50%;
  flex-flow: column;
  justify-content: center;
}
.ContactCopy {
  width: 90%;
  margin: auto;
  line-height: 2em;
  text-align: center;
}
.ContactCopy h3 {
  font-family: "Univers";
  margin-bottom: 4vh;
}

@media screen and (max-width: 1024px) {
  .ContactInnerWrapper {
    display: flex;
    flex-direction: column-reverse;
    margin-top: 20vh;
  }
  .rightContactPanel {
    width: 100%;
    height: 50%;
  }
  .leftContactPanel {
    width: 100%;
    height: 50vh;
  }
  .leftContactPanel h1 {
    font-size: 7vw;
    margin-top: 2vh;

  }
}

@media screen and (max-width: 768px) {

  .leftContactPanel h1 {
    font-size: 12vw;
    margin-top: 2vh;
  }
  .leftContactPanel {
    height: 40vh;
  }
  .ContactCopy h3 {
    font-family: "Univers";
    margin-bottom: 4vh;
    font-size: 0.8em;
  }
}
