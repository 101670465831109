.galleryWrapper {
  display: block;
  flex-direction: row;
  min-height: calc(100vh - 34px);
  height: auto;
  margin: 0 auto;
  width:100%;
  min-width: fit-content;

}
.galleryInnerWrapper{
    margin: 5rem  0;
}

.row {
  display: flex;
  flex-wrap: wrap;
  padding: 0 4px;
}

/* Create four equal columns that sits next to each other */
.column {
  flex: 25%;
  max-width: 25%;
  padding: 0 4px;
}

.column img {
  margin-top: 8px;
  vertical-align: middle;
  width: 100%;
  cursor:zoom-in;
  opacity:1;
}
.column img:hover{
    opacity:0.8;
    transition: opacity .5s ease-in-out;
    
    
}
/* Responsive layout - makes a two column-layout instead of four columns */
@media screen and (max-width: 800px) {
  .column {
    flex: 50%;
    max-width: 50%;
  }
}

/* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
  .column {
    flex: 100%;
    max-width: 100%;
  }
}
