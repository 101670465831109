@font-face {
  font-family: "Cooper Black Italic";
  src: url("./fonts/CooperBlackStd-Italic.otf");
}
@font-face {
  font-family: "Cooper Black";
  src: url("./fonts/CooperBlackStd.otf");
}
@font-face {
  font-family: "Univers";
  src: url("./fonts/UniversLTStd.otf");
}
@font-face {
  font-family: "Univers Lite";
  src: url("./fonts/UniversLTStd-ThinUltraCn.otf");
}

.HomePFlex {
  display: none;
}
.DesktopPics {
  display: none;
}
.AboutWrapper {
  display: block;
  flex-direction: row;
  min-height: calc(100vh - 34px);
  height: auto;
  max-width: 99%;
  min-width: fit-content;

}
.InnerWrapper {
  height: auto;
  width: auto;
}
.PresImg {
  height: 100%;
  width: 100%;
  background-image: url("./images/present_top.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: top;
}
.ImgWrapper {
  height: 30vh;
  margin-top: 3em;
}
.CopyWrapper {
  height: auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: auto;
}
.HeadingWrapper {
  display: flex;
  width: auto;
  justify-content: bas;
  height: 3vh;
  padding-left: 1em;
  margin: 0;
}
.TabletP {
  display: none;
}
.HeadingWrapper h1 {
  font-family: "Cooper Black";
  font-size: 1.8rem;
  margin-right: 0.6em;
}
.HeadingWrapper h2{
  font-family: "Univers";
  font-size: 1em;
}
.MobileCopy {
  width: 100%;
  margin: auto;
}
.MobileCopy p {
  font-family: "Univers";
  margin: auto;
  width: 90%;
}
.MobileCopy img {
  width: 100%;
  padding: 1em;
}
.CopyWrapper p {
  font-family: "Univers";
  margin: auto;
  width: 90%;
  padding: 1em;
}
.ReasonsWhy {
  width: 99%;
  height: auto;
}
.h1Outline {
  -webkit-text-stroke: 0.3px black;
  font-family: "Univers";
  font-size: 4em;
  margin-left: 0.3em;
  color: white;
}
.RibbonFeetContainer {
  height: 60vh;
  width: 100%;
}
.RibbonFeet {
  height: 100%;
  background-image: url(./images/ToyDrive2021_PresentDudeLong_Bottom.svg);
  background-repeat: no-repeat;
  background-position: top;
  background-size: contain;
}
.TabletPictures {
  display: none;
}

/* MEDIA QUEERIES  */

@media screen and (min-width: 770px) {
  .TitleWrapper,
  .Title,
  .MobileCopy,
  .RibbonFeetContainer,
  .ImgWrapper {
    display: none;
  }
  .AboutWrapper {
    display: flex;
    flex-direction: row-reverse;
  }

  .InnerWrapper {
    width: 60%;
    height: 100%;
    margin-top: 15vh;
    margin-left: auto;
  }
  .CopyWrapper {
    width: 80%;
  }
  .CopyWrapper p {
    font-size: 1.5rem;
  }
  .CopyWrapper h1 {
    font-size: 2rem;
  }
  .CopyWrapper h2 {
    font-size: 1.rem;
  }
  .ReasonsWhy h1 {
    font-size: 6rem;
  }
  .TabletPictures {
    display: block;
    height: auto;
    width: 40%;
    justify-content: center;
    margin-left: 5vw;
    margin-top: 15vh;
  }
  .TabletPictures img {
    width: 100%;
    padding: 0.8em;
  }

  .PresentTablet {
    height: 80vh;
    width: 100%;
    background-image: url(./images/PresentDudeLong.svg);
    background-repeat: no-repeat;
    background-position: center;
  }
  .TabletP {
    display: flex;
  }
}

@media screen and (min-width: 1080px) {
  .TabletPictures img {
    display: none;
  }
  .TabletP {
    display: none;
  }
  .DesktopPics {
    display: flex;
    width: 30vw;
    margin-bottom: 3vh;
  }
  .DesktopPics img {
    padding: 0.5em;
    width: 15vw;
  }
  .HomePFlex {
    display: flex;
    padding: 0;
    width: 55vw;
    margin-left: -3vw;
    height: auto;
  }
  .HeadingWrapper:first-child {
    margin-left: -5vw;
  }

  .TabletPictures {
    height: 200vh;
    margin-top: 20vh;
  }
  .PresentTablet {
    height: 80%;
  }
  .InnerWrapper {
    width: 70%;
    margin-top: 20vh;
  }
  .CopyWrapper h1 {
    font-size: 4rem;
    padding-bottom: 4rem;
  }
  .CopyWrapper p {
    padding: 3em 1em;
  }
.alignP{
  margin-left: -5vw;
}
  .ReasonsWhy {
    display: flex;
    margin-left: -3vw;
    max-width: 80vw;
    margin-top: 5vh;
  }
  .ReasonsWhy h1 {
    font-size: 7.9rem;
    color:white;
  -webkit-text-stroke: 0.06px black;

  }
  .ReasonsWhy p {
    padding: 1em ;
    margin-top: -10vh;
    margin-bottom: 10vh;
  }
  .WhyInnerWrapper {
    width: 100%;
  }
}
