/* FANTS  */


@font-face {
  font-family: "Cooper Black Italic";
  src: url("./fonts/CooperBlackStd-Italic.otf");
}
@font-face {
  font-family: "Cooper Black";
  src: url("./fonts/CooperBlackStd.otf");
}
@font-face {
  font-family: "Univers";
  src: url("./fonts/UniversLTStd.otf");
}

*, *::before, *::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  max-width: 100vw;
  overflow-x: none;
}

a {
  text-decoration: none;
  color: black;
}

html,
body {
  height: 100%;
  position: relative;
}

.ThankyouWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-height: calc(100vh - 34px);
  height: auto;
  max-width: 100%;
  min-width: fit-content;
  overflow-y: auto;
}
.tyImage{
 width:900px;
 padding:3rem;
  margin-top: 5vh;
}

.typhone{
  display:none;
}
.svgDiv{
background-image: url('./images/dosomething.svg');
background-repeat: no-repeat;
background-position: center;
padding:1em;
background-size:contain;
width: 30vw;
height:10vh;
}
.HomeButtonWrapper {
  display: flex;
  position: static;
  float: left;
  width: 95vw;
  justify-content: space-evenly;
  min-width: fit-content;
  flex-direction: row;
  text-decoration: none;
}

.SidebarLink {
  font-family: "Univers";
  color: #f86149;
  font-size: 4rem;
  /* padding: 0 10 20 20; */
}
.DSLogoSide {
  font-family: "Cooper Black";
  position: absolute;
  top: 25px;

  font-size: 5rem;
  color: white;
}

/* FOOOOOOTTER  */
.FooterWrapper {
  width: 100%;
  position: relative;
  bottom: 0;
  border-top: 2px solid #8c848c;
  justify-content: center;
  height: 20vh;
  display: flex;
  align-items: center;
}

.FooterFlex {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  margin: auto;
  text-align: center;
  max-height: 20vh;
  color: black;
  width: 95%;
}

.FooterFlex p {
  font-family: "Univers";
  text-align: right;
  font-size: 1.5vw;
}

.DSLogo {
  font-family: "Cooper Black";
  font-size: 3.5vw;
}

/* AC CONTACTFORM 
 */

._field12 label,
._x40490770 label,
._x02542146 label,
._x86064588 label,
._submit {
  font-family: "Univers";
  text-align: center;
  font-size: 3rem;
}
._submit{
    margin-top: -2em;
}
._form_42{
    margin-top: -3em;
}

/* media queries */

@media screen and (max-width: 1024px) {
  .DSLogo {
    font-size: 5vw;
  }
    .tyImage{
display: none;
  }
  .typhone{
    display: block;
    padding:3em;
    margin-top: 5vh;
  }

}

@media screen and (max-width: 770px) {
  .ContactWrapper {
    display: flex;
    flex-direction: column;
  }
  .ContactWrapperText {
    width: 90vw;
    margin: auto;
  }
  .DSLogo {
    display: none;
  }

  .SidebarLink {
    font-family: "Univers";
    color: #f86149;
    font-size: 2.5rem;
    margin-bottom: 2em;
  }
  .DSLogoSide {
    font-family: "Cooper Black";
    /* position: absolute; */
    margin-top: vh;
    font-size: 8vw;
    color: white;
    margin-bottom: 5vh;
  }

  .FooterFlex p {
    font-family: "Univers";
    text-align: right;
    font-size: 3vw;
    padding: 2em;
    /* width: 40%; */
  }
  .tyImage{
display: none;
  }
  .typhone{
    display: block;
    padding:1em;
    margin-top: 4vh;
  }
  .svgDiv{
    width:55vw;
    margin-top: -30px;
  }
}
@media screen and (min-width:650px){
    .SidebarLink{
        font-size: 2.5rem;
    margin-bottom:-0.8em;}
}
@media screen and (max-height:456px){
    .SidebarLink{
        font-size: 1em;
        margin-bottom: 0em;
    }
}

