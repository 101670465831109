@font-face {
  font-family: "Cooper Black Italic";
  src: url("./fonts/CooperBlackStd-Italic.otf");
}
@font-face {
  font-family: "Cooper Black";
  src: url("./fonts/CooperBlackStd.otf");
}
@font-face {
  font-family: "Univers";
  src: url("./fonts/UniversLTStd.otf");
}

img{
    max-width: 100%;
}
*, *::before, *::after{
    box-sizing: border-box;
    margin:0;
}
.HomeWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-height: calc(100vh - 34px);
  height: auto;
  max-width: 100%;
  min-width: fit-content;
  overflow-y: auto;
  margin-top: 6vh;
}
.sec1{
display:flex;
flex-direction: column;
align-items: center;
justify-content: center;
text-align: center;
    width:100%;
    padding:1em;
}

.copyContainer{
    padding:1em 2em;
}
.sec1 h1, h2 {
    font-family: Cooper Black;
    padding:0.25em;
}

.sec1 h1 {
    font-size: 3.5rem;
}

.sec1 p{
    font-family: Univers;
    font-size: 1.2rem;
    margin-bottom: 0.5em;
}
.threePresents{
    width:50%;
    padding:1.2em 0;
    max-width:320px;
}
.sec2{
    background-color:#FFF2E1;
    width:100%;
    display:flex;
    padding:1.25em 0;
    flex-direction:column;
    align-items: center;
}
.sec2 h1 {
    font-family: 'Cooper Black';
    font-size: 3.5rem;
}

.sec2 p{
    font-family: Univers;
    font-size:1.2rem;
    padding:1em 1.25em;
    margin-bottom: 1em;
    max-width: 700px;
}

.fourPresentsMobile{
    width:50%;
    max-width:320px;
    padding: 1.2em 0;
}
.fourPresentsHome{
    display:none;
    width:50%;
    max-width:320px;
    padding: 1.2em 0;
}
.sec3{
    width:100%;
    display:flex;
    padding:1.25em 0;
    flex-direction:column;
    align-items: center;

}
.sec3 p{
  font-family: Univers;
    font-size:1.2rem;
    padding:1em 1.25em;
    margin-bottom: 1em;
    max-width: 700px;
}
.donateFlex{
    display:flex;
    flex-direction: column;
    margin-bottom:10vh;
}
.presentNewYear{
    padding:1.2em;
    max-width: 520px;
}
.donateFlex p {
    margin:0;
    padding:0;
}
.donateBtn {
  font-family: "Univers";
  font-size: 2rem;
  color:white;
  background-color:rgba(167,129,242,255);
  border-radius:2px;
  padding: .5rem 2rem;
  margin-top: .5em;
  max-width: 600px;
}
.donateBtn:hover {
  background-color: rgba(33, 174, 149, 255);
  color: black;
  transition: ease-in-out 0.2s;
}
.contactCopy{
    z-index: 2;
}

/* media queries */

/*desktop*/

@media screen and (min-width:800px){
.sec1{
    flex-direction: row;
    padding:5em 0;
    justify-content: space-evenly;
}
.copyContainer{
    width:50%;
    max-width: 800px;
}
.btnFlex{
    display:flex;
    margin-top: 3em;
    width:80%;
    justify-content:space-evenly; 
    align-items: flex-start;
}
.sec2{
    padding:5em 0;
}
.sec2flex{
    display: flex;
}
.sec3{
    padding:5em 0
}
.fourPresentsHome{
    display:block;
}
.fourPresentsMobile{
    display:none;
}
}


