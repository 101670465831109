@font-face {
  font-family: "Cooper Black Italic";
  src: url("./fonts/CooperBlackStd-Italic.otf");
}
@font-face {
  font-family: "Cooper Black";
  src: url("./fonts/CooperBlackStd.otf");
}
@font-face {
  font-family: "Univers";
  src: url("./fonts/UniversLTStd.otf");
}

.iFrameWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-height: calc(100vh - 34px);
  height: auto;
  max-width: 100%;
  min-width: fit-content;
  overflow-y: auto;
  align-items: center;
  margin-top: 12vh;
  background-image: url('./images/DoSomething_DonatePage_Graphic_1920.svg');
  background-repeat: no-repeat;
  background-position: bottom;

}


.borderbox{
  width:400px;
  height:auto;
  padding-top: 30px;
  padding-bottom: 30px;
  margin-bottom: 3vh;
  border-top: 2px solid rgb(138, 138, 138);
  border-bottom: 2px solid rgb(138, 138, 138);
}
.DonorCTA{
  margin-bottom: 3vh;
  padding:1em;
  width:40vw;
  font-family: 'Univers';
}

.DonorContent {
  width: 40%;
  margin: auto;
  text-align: center;
}

.DonorContent p {
  font-family: "Univers";
  margin-bottom: 10vh;
}

@media screen and (max-width:780px){
  .DonorCTA{
    padding: 0;
    width:70vw;
    font-size: 2vw;
  }
  .DonorContent{
    width: 70vw;
    margin-bottom: 0;
    padding:1em;
    font-size: 2vw;
  }
}